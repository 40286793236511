import "../styles/demo.scss";
import Nav from "./Nav";
import Footer from "./Footer";
import step1 from "../images/instructions/IMAGE 1.png";
import step2 from "../images/instructions/IMAGE 2.png";
import step3 from "../images/instructions/IMAGE 3.png";
import step4 from "../images/instructions/IMAGE 4.png";
import step5 from "../images/instructions/IMAGE 5.png";
import step6 from "../images/instructions/IMAGE 6.png";
import step7 from "../images/instructions/IMAGE 7.png";

const Demo = () => {
    return (
        <main className="demo" >
            <Nav page={"demo"} />
            <div className="instructions_row">
                <div className="instruction_card" id={1}>
                    <img src={step1} className="" alt="Our Facilities" />
                </div>
                <div className="instruction_card" id={2}>
                    <img src={step2} className="" alt="Shampoo" />
                    <div className="instructions">
                        <h2>Work through coat by hand</h2>
                        <h3>Approx. time: 1 - 2 minutes</h3>
                    </div>
                </div>
                <div className="instruction_card" id={3}>
                    <img src={step3} className="" alt="Warm Rinse" />
                    <div className="instructions">
                        <h2>Use as required.</h2>
                        <h3>Approx. time: 1 - 2 minutes</h3>
                    </div>
                </div>
                <div className="instruction_card" id={4}>
                    <img src={step4} className="" alt="Conditioner" />
                    <div className="instructions">
                        <h2>For a healthy coat.</h2>
                        <h3>Approx. time: 1 - 2 minutes</h3>
                    </div>
                </div>
                <div className="instruction_card" id={5}>
                    <img src={step5} className="" alt="Anti-Flea Shampoo" />
                    <div className="instructions">
                        <h2>Rinse for flea control (Leave in).</h2>
                        <h3>Approx. time: 30 - 60 seconds</h3>
                    </div>
                </div>
                <div className="instruction_card" id={6}>
                    <img src={step6} className="" alt="Blow Dry" />
                    <div className="instructions">
                        <h2>Blow dry coat on low or high setting.</h2>
                        <h3>Approx. time: 5 minutes</h3>
                    </div>
                </div>
                <div className="instruction_card" id={7}>
                    <img src={step7} className="" alt="Disinfect Tub" />
                    <div className="instructions">
                        <h2>
                            Take dog out of tub. For hygiene purposes, rinse
                            tub.
                        </h2>
                        <h3>Approx. time: 30 - 60 seconds</h3>
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default Demo;
