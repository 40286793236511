import "../styles/nav.scss";
import logo from "../images/dog_wash_transparent.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import pawIcon from "../images/icons/map_markers/Paw_Icon_Small.png";

const Nav = (props) => {

    const navigate = useNavigate();
    useEffect(() => {
        document.getElementById(props.page).style.visibility = "visible";
    }, [props]);

    return (
        <nav id="nav">
            <div className="logo">
                <img
                    className="main__logo"
                    src={logo}
                    alt="Pawwash logo"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="menu">
                <div className="menu_item"  onClick={() => navigate("/")}>
                    <img
                        src={pawIcon}
                        alt="Paw Icon"
                        id="home"
                       
                    />

                    <span>Home</span>
                </div>
                <div className="menu_item"  onClick={() => navigate("/demo")} >
                    <img
                        src={pawIcon}
                        alt="Paw Icon"
                        id="demo"
                       
                    />

                    <span>Demo</span>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
