import { useState, useEffect } from "react";
import mapIcon from "../images/icons/map_markers/Paw_Icon_Small.png";
import "../styles/home.scss";
import pawIcon from "../images/icons/map_markers/Paw_Icon_Small.png";
import Footer from "./Footer";
import Nav from "./Nav";
import axios from "axios";
import CallIcon from "@mui/icons-material/Call";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import {
    GoogleMap,
    useJsApiLoader,
    MarkerF,
    InfoWindow,
} from "@react-google-maps/api";

export default function Home() {
    const url = "http://nitrous.rt-itservices.co.uk";
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [markerData, setMarkerData] = useState([])

    //Get Pawwash data on load
    useEffect(() => {
        axios
            .get(`${url}/pawwash/locations`)
            .then((res) => {
                setMarkerData(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // Map container style
    const containerStyle = {
        width: "100%",
        height: "60vh",
        "@media(maxWidth: 690px)": {
            heigth: "40vh",
        },
        "@media(minWidth: 851px) and (maxWidth:1300px)": {
            heigth: "40vh",
        },

    };

    //Map centre point
    const center = {
        lat: 55.90072430176191,
        lng: -4.230517394800327,
    };

    //Load map key
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
    });

    //Set marker click for info window
    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    return (
        <main className="home">
            <Nav page={"home"} />
            <div className="location__map">
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                        options={{ streetViewControl: false }}
                    >
                        {markerData && markerData.length > 0 ? (
                            markerData.map(function(site, index){
                                return(
                                    <MarkerF
                                    icon={mapIcon}
                                    position={{
                                        lat: site.address.latitude,
                                        lng: site.address.longitude,
                                    }}
                                    onClick={() => handleMarkerClick(site)}
                                    key={`${index}-map`}
                                />
                                )
                            })
                        ): null}
                        {selectedMarker ? (
                            <InfoWindow
                                position={{
                                    lat: selectedMarker.address.latitude,
                                    lng: selectedMarker.address.longitude,
                                }}
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <>
                                    <div className="infowindow_header">
                                        <img src={pawIcon} />
                                        <h1>{selectedMarker.name}</h1>
                                    </div>
                                    <div className="store_info">
                                        <p>
                                            <LocationOnIcon />
                                            <b>Address: </b>{" "}
                                            {`${selectedMarker.address.addressLine1}, ${selectedMarker.address.addressLine3}, ${selectedMarker.address.postcode}`}
                                        </p>
                                        <p>
                                            <AccessTimeIcon />
                                            <b>Opening Hours: </b> 24/7
                                        </p>
                                        <p>
                                            <CallIcon />
                                            <b>Phone: </b>{" "}
                                            {selectedMarker.address.phoneNumber}
                                        </p>
                                    </div>
                                </>
                            </InfoWindow>
                        ) : null}
                    </GoogleMap>
                ) : null}
            </div>
            <Footer />
        </main>
    );
}
