import "../styles/footer.scss";
import facebook from "../images/icons/sm/facebook.webp";
import insta from "../images/icons/sm/instagram.webp";
import x from "../images/icons/sm/x_transparent.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footerContainer">
        <div className="smIcons">
          <a
            href="https://www.facebook.com/profile.php?id=61550823506649 "
            target="_blank"
          >
            <img src={facebook} alt="Facebook Logo" />
          </a>
          <a href="https://www.instagram.com/pawwash_uk/" target="_blank">
            <img src={insta} alt="Instagram Logo" />
          </a>
          <a href="https://twitter.com/pawwash_uk" target="_blank">
            <img src={x} alt="Twitter Logo" />
          </a>
        </div>
      </div>
      <p>
        Designed & developed by{" "}
        <a href="https://nitrosolutions.co.uk/" target="_blank">
          Nitro Soloutions
        </a>
      </p>
    </footer>
  );
};

export default Footer;
